import React from 'react';

interface IconProps {
    className?: string;
}

const ShowIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 27.0508 16.748">
            <g>
                <rect height="16.748" opacity="0" width="27.0508" x="0" y="0"/>
                <path
                    d="M13.3496 16.748C21.2402 16.748 26.6895 10.3711 26.6895 8.37891C26.6895 6.37695 21.2305 0.00976562 13.3496 0.00976562C5.56641 0.00976562 0 6.37695 0 8.37891C0 10.3711 5.55664 16.748 13.3496 16.748ZM13.3496 15.2051C6.91406 15.2051 1.70898 9.75586 1.70898 8.37891C1.70898 7.2168 6.91406 1.55273 13.3496 1.55273C19.7559 1.55273 24.9805 7.2168 24.9805 8.37891C24.9805 9.75586 19.7559 15.2051 13.3496 15.2051ZM13.3496 13.8379C16.377 13.8379 18.8281 11.3867 18.8281 8.35938C18.8281 5.33203 16.377 2.88086 13.3496 2.88086C10.3223 2.88086 7.86133 5.33203 7.86133 8.35938C7.86133 11.3867 10.3223 13.8379 13.3496 13.8379ZM13.3496 10.1855C12.3438 10.1855 11.5332 9.375 11.5332 8.36914C11.5332 7.36328 12.3438 6.55273 13.3496 6.55273C14.3555 6.55273 15.166 7.36328 15.166 8.36914C15.166 9.375 14.3555 10.1855 13.3496 10.1855Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default ShowIcon;
