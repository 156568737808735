// services/campaignService.ts
import {CreateCampaignModel} from '../models/CreateCampaignModel';
import axiosInstance from '../utils/axiosConfig';
import {UpdateCampaignModel} from "../models/UpdateCampaignModel";
import {CampaignModel} from "../models/CampaignModel";
import {CampaignStatisticsModel} from "../models/CampaignStatisticsModel";
import {PaginationModel} from "../models/PaginationModel";

export async function fetchCampaigns(page: number, limit: number): Promise<PaginationModel<CampaignModel>> {
    try {
        const response = await axiosInstance.get("/campaigns", {
            params: {
                page,
                limit,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Failed to fetch campaigns', error);
        throw new Error('Failed to fetch campaigns');
    }
}

export async function updateCampaign(id: number, campaignData: UpdateCampaignModel): Promise<void> {
    try {
        await axiosInstance.patch(`/campaigns/${id}`, campaignData);
    } catch (error) {
        console.error('Failed to update campaign', error);
        throw new Error('Failed to update campaign');
    }
}

export async function getCampaignById(id: number): Promise<CampaignModel> {
    try {
        return (await axiosInstance.get(`/campaigns/${id}`)).data;
    } catch (error) {
        console.error('Failed to get campaign', error);
        throw new Error('Failed to get campaign');
    }
}

export async function getCampaignStatisticsById(id: number): Promise<CampaignStatisticsModel> {
    try {
        return (await axiosInstance.get(`/campaigns/${id}/statistics`)).data;
    } catch (error) {
        console.error('Failed to update campaign', error);
        throw new Error('Failed to get campaign statistics');
    }
}

export async function createCampaign(campaignData: CreateCampaignModel): Promise<void> {
    try {
        await axiosInstance.post("/campaigns", campaignData);
    } catch (error) {
        console.error('Failed to create campaign', error);
        throw new Error('Failed to create campaign');
    }
}

export async function cancelCampaign(campaignId: number): Promise<void> {
    try {
        await axiosInstance.patch(`/campaigns/${campaignId}/cancel`);
    } catch (error) {
        console.error(`Failed to cancel campaign with ID ${campaignId}`, error);
        throw new Error('Failed to cancel campaign');
    }
}

export async function pauseCampaign(campaignId: number): Promise<void> {
    try {
        await axiosInstance.patch(`/campaigns/${campaignId}/pause`);
    } catch (error) {
        console.error(`Failed to pause campaign with ID ${campaignId}`, error);
        throw new Error('Failed to pause campaign');
    }
}

export async function resumeCampaign(campaignId: number): Promise<void> {
    try {
        await axiosInstance.patch(`/campaigns/${campaignId}/resume`);
    } catch (error) {
        console.error(`Failed to resume campaign with ID ${campaignId}`, error);
        throw new Error('Failed to resume campaign');
    }
}
