import React, {useEffect, useRef, useState} from 'react';

import {cancelCampaign, fetchCampaigns, pauseCampaign, resumeCampaign} from '../services/campaignService';
import {CampaignModel} from '../models/CampaignModel';
import Button from "../components/Button";
import {useNavigate, useSearchParams} from 'react-router-dom';
import {CampaignStatus} from "../enums/CampaignStatus";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import KebabIcon from "../icons/Kebab";
import StatsIcon from "../icons/Stats";
import EditIcon from "../icons/Edit";
import CancelIcon from "../icons/Cancel";
import {getCampaignStatusColor, getCampaignStatusLabel} from "../utils/enumUtils";
import ResumeIcon from "../icons/Resume";
import PauseIcon from "../icons/Pause";
import {useMediaQuery} from "react-responsive";

const CampaignsPage = () => {
    const [campaigns, setCampaigns] = useState<CampaignModel[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const pageSize = 30;
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState<CampaignModel | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dropDown = useRef<HTMLDivElement | null>(null)
    const page = parseInt(searchParams.get('page') || '1', 10);
    const isMobile = useMediaQuery({query: "(max-width: 768px)"});

    const handleDropdownItemClick = (path: string) => {
        navigate(path);
        setDropdownOpen(false);
    };

    const handleCancelCampaignConfirmation = () => {
        setIsModalOpen(true);
        setDropdownOpen(!dropdownOpen);
    };

    const toggleDropdown = (campaign: CampaignModel) => {
        setSelectedCampaign(campaign);
        setDropdownOpen(!dropdownOpen);
    };

    const closeDropDowns = (e: MouseEvent) => {
        const target = e.target as HTMLElement;

        if (dropdownOpen && !dropDown.current?.contains(target)) {
            setDropdownOpen(false)
        }
    }

    document.addEventListener('mousedown', closeDropDowns)

    useEffect(() => {
        const loadCampaigns = async () => {
            try {
                setIsLoading(true)
                const pagination = await fetchCampaigns(page, pageSize);
                setCampaigns(pagination.data);
                setTotalPages(pagination.meta.totalPages);
                setTotalItems(pagination.meta.totalItems)
                setIsLoading(false)
            } catch (error) {
                console.error('Failed to load campaigns', error);
            }
        };

        void loadCampaigns();
    }, [page]);

    const handleNextPage = () => {
        if (page < totalPages) setSearchParams({page: (page + 1).toString()});
    };

    const handlePrevPage = () => {
        if (page > 1) setSearchParams({page: (page - 1).toString()});
    };

    const handleCancelCampaign = async (campaignId: number) => {
        try {
            await cancelCampaign(campaignId);
            const updatedCampaigns = campaigns.map(campaign =>
                campaign.id === campaignId ? {...campaign, status: CampaignStatus.CANCELED} : campaign
            );
            setCampaigns(updatedCampaigns);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Failed to cancel campaign', error);
        }
    };

    const handlePauseCampaign = async (campaignId: number) => {
        try {
            await pauseCampaign(campaignId);
            const updatedCampaigns = campaigns.map(campaign =>
                campaign.id === campaignId ? {...campaign, status: CampaignStatus.PAUSED} : campaign
            );
            setCampaigns(updatedCampaigns);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Failed to stop campaign', error);
        }
    };

    const handleResumeCampaign = async (campaignId: number) => {
        try {
            await resumeCampaign(campaignId);
            const updatedCampaigns = campaigns.map(campaign =>
                campaign.id === campaignId ? {...campaign, status: CampaignStatus.ACTIVE} : campaign
            );
            setCampaigns(updatedCampaigns);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Failed to stop campaign', error);
        }
    };

    return (
        <div className="container mx-auto">
            <div className="flex flex-row items-center align-middle justify-between mb-4 sm:mb-6">
                <div className="flex flex-row gap-2 sm:gap-4 items-center">
                    <h1 className="text-2xl sm:text-4xl font-bold mb-0">Кампании</h1>
                    <span
                        className={`text-sm sm:text-base block sm:mt-0 px-3 py-1 sm:py-2 rounded-xl font-medium bg-primary`}>{totalItems}</span>
                </div>
                <Button
                    label={isMobile ? '+' : 'Добавить'}
                    onClick={() => navigate('/create-campaign')}
                    className="px-6 py-2 sm:px-10"
                />
            </div>

            <div className="border border-gray-200 rounded-lg overflow-x-scroll">
                <table className="table-auto w-full text-center whitespace-no-wrap">
                    <thead>
                    {!isLoading && <tr className="border-b border-gray-200">
                        <th className="px-2 sm:px-4 py-5 font-medium text-gray-500">#</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Наименование</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Дата начала</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Дата окончания</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Статус</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">2GIS</th>
                        <th className="px-2 sm:px-4 py-5 font-medium hidden sm:table-cell">Дата создания</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Действия</th>
                    </tr>}
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan={7} className="px-4 py-4 text-center">
                                <Loader/>
                            </td>
                        </tr>
                    ) : campaigns.length === 0 ? (
                        <tr>
                            <td colSpan={8} className="px-4 py-4 text-center text-gray-500">
                                Кампании не найдены
                            </td>
                        </tr>
                    ) : (
                        campaigns.map((campaign, index) => (
                            <tr key={index} className="border-t border-gray-200 hover:bg-gray-100">
                                <td className="px-2 sm:px-4 py-2 text-gray-500">{campaign.id}</td>
                                <td className="px-2 sm:px-4 py-2 max-w-48">{campaign.name}</td>
                                <td className="px-2 sm:px-4 py-2">{new Date(campaign.periodStart).toLocaleDateString("ru-RU", {timeZone: 'Asia/Almaty'})}</td>
                                <td className="px-2 sm:px-4 py-2">{new Date(campaign.periodEnd).toLocaleDateString("ru-RU", {timeZone: 'Asia/Almaty'})}</td>
                                <td className="px-2 sm:px-4 py-2">
                                    <span
                                        className={`block w-full px-2 py-1 sm:py-2 rounded-xl font-medium ${getCampaignStatusColor(campaign.status)}`}
                                    >
                                        {getCampaignStatusLabel(campaign.status)}
                                    </span>
                                </td>
                                <td className="px-2 sm:px-4 py-2">
                                    <a target="_blank" rel="noreferrer" href={campaign.twoGisUrl}
                                       className="text-blue-500 hover:underline cursor-pointer">
                                        Ссылка
                                    </a>
                                </td>
                                <td className="px-2 sm:px-4 py-2 hidden sm:table-cell">{new Date(campaign.createdAt).toLocaleString("ru-RU", {timeZone: 'Asia/Almaty'})}</td>
                                <td className="px-2 sm:px-4 py-2 relative">
                                    <button
                                        onClick={() => toggleDropdown(campaign)}
                                        className="inline-flex items-center p-2 text-sm font-medium text-gray-900 rounded-lg hover:bg-gray-100 focus:outline-none"
                                    >
                                        <KebabIcon className="w-5 h-5"/>
                                    </button>

                                    {/* Dropdown menu */}
                                    {(dropdownOpen && selectedCampaign?.id === campaign.id) && (
                                        <div ref={dropDown}
                                             className="absolute right-0 top-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-32">
                                            <ul className="py-2 text-sm text-gray-700"
                                                aria-labelledby="dropdownMenuIconButton">
                                                <li className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleDropdownItemClick(`/campaign/${campaign.id}/stats`)}>
                                                    <StatsIcon className="mr-2 w-5 h-5"/>
                                                    <p className="text-gray-700 hover:text-gray-900">Инфо</p>
                                                </li>
                                                {(campaign.status !== CampaignStatus.FINISHED && campaign.status !== CampaignStatus.CANCELED) && (
                                                    <li className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                        onClick={() => handleDropdownItemClick(`/campaign/${campaign.id}/edit`)}>
                                                        <EditIcon className="mr-2 w-5 h-5"/>
                                                        <p className="text-gray-700 hover:text-gray-900">Изменить</p>
                                                    </li>
                                                )}
                                                {campaign.status === CampaignStatus.PAUSED && (
                                                    <li className="flex items-center px-4 py-2 hover:bg-gray-100 text-lime-600 cursor-pointer"
                                                        onClick={() => handleResumeCampaign(campaign.id)}>
                                                        <ResumeIcon className="mr-2 w-5 h-5"/>
                                                        <p className="text-lime-600 hover:text-lime-700">Включить</p>
                                                    </li>
                                                )}
                                                {(campaign.status === CampaignStatus.ACTIVE) && (
                                                    <li onClick={() => handlePauseCampaign(campaign.id)}
                                                        className="flex items-center px-4 py-2 hover:bg-gray-100 text-orange-500 cursor-pointer">
                                                        <PauseIcon className="mr-2 w-5 h-5"/>
                                                        <p className="text-orange-500 hover:text-orange-600">Пауза</p>
                                                    </li>
                                                )}
                                                {(campaign.status !== CampaignStatus.FINISHED && campaign.status !== CampaignStatus.CANCELED) && (
                                                    <li onClick={handleCancelCampaignConfirmation}
                                                        className="flex items-center px-4 py-2 hover:bg-gray-100 text-red-500 cursor-pointer">
                                                        <CancelIcon className="mr-2 w-5 h-5"/>
                                                        <p className="text-red-500 hover:text-red-600">Отменить</p>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>

            {!isLoading && <div className="mt-4 flex flex-row justify-between items-center">
                <Button
                    label="Назад"
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
                <span
                    className="inline-flex items-center justify-center h-10 leading-none text-center">{page}/{totalPages}</span>
                <Button
                    label="Вперед"
                    onClick={handleNextPage}
                    disabled={page >= totalPages}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
            </div>}

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAction={() => handleCancelCampaign(selectedCampaign!.id)}
                title="Подтверждение отмены кампании"
                actionLabel="Подтвердить"
                description="Вы уверены, что хотите отменить кампанию?"
            />
        </div>
    );

};

export default CampaignsPage;
