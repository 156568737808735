import React from 'react';

interface IconProps {
    className?: string;
}

const HideIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 27.0508 20.9473">
            <g>
                <rect height="20.9473" opacity="0" width="27.0508" x="0" y="0"/>
                <path
                    d="M6.09793 5.95835C3.42007 7.66597 1.70898 9.81222 1.70898 10.4785C1.70898 11.8555 6.91406 17.3047 13.3496 17.3047C14.591 17.3047 15.788 17.1001 16.909 16.7565L18.1743 18.0203C16.7125 18.5373 15.0905 18.8477 13.3496 18.8477C5.55664 18.8477 0 12.4707 0 10.4785C0 9.32842 1.83709 6.73767 4.88274 4.74461ZM26.6895 10.4785C26.6895 11.5922 24.9865 14.0762 22.1037 16.0495L20.9104 14.857C23.4019 13.2226 24.9805 11.2354 24.9805 10.4785C24.9805 9.31641 19.7559 3.65234 13.3496 3.65234C12.2418 3.65234 11.1704 3.82019 10.1598 4.11277L8.8762 2.82995C10.2502 2.37722 11.7533 2.10938 13.3496 2.10938C21.2305 2.10938 26.6895 8.47656 26.6895 10.4785ZM15.6012 15.4502C14.9162 15.7648 14.1532 15.9375 13.3496 15.9375C10.3223 15.9375 7.86133 13.4863 7.86133 10.459C7.86133 9.65555 8.03466 8.8927 8.35013 8.20785ZM18.8281 10.459C18.8281 11.148 18.7011 11.8072 18.4638 12.4118L11.3933 5.34558C11.9993 5.10775 12.6598 4.98047 13.3496 4.98047C16.377 4.98047 18.8281 7.43164 18.8281 10.459Z"
                    fillOpacity="0.85"/>
                <path
                    d="M20.9766 18.9355C21.2793 19.2383 21.7383 19.2578 22.041 18.9355C22.3633 18.6133 22.3438 18.1738 22.041 17.8711L5.71289 1.55273C5.41992 1.25977 4.94141 1.25977 4.63867 1.55273C4.35547 1.83594 4.35547 2.33398 4.63867 2.61719Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default HideIcon;
