import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Menu, MenuItem, Sidebar} from 'react-pro-sidebar';
import LogoIcon from "../icons/Logo";
import MessageIcon from "../icons/Message";
import PersonIcon from "../icons/Person";
import ChartIcon from "../icons/Chart";
import MegaphoneIcon from "../icons/Megaphone";
import ChevronRightIcon from "../icons/ChevronRight";
import ChevronLeftIcon from "../icons/ChevronLeft";

const SideBar = () => {
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(true);

    const toggleCollapse = () => {
        setCollapsed(prevState => !prevState);
    };

    return (
        <Sidebar
            collapsed={collapsed}
            className="h-screen fixed top-0 left-0 z-10"
            style={{
                position: 'fixed',
                border: "none",
            }}
            backgroundColor='#f3f8fc'
        >
            <div className="flex items-center p-4 mb-2">
                {collapsed ? (
                    <div
                        onClick={toggleCollapse}
                        className="bg-primary rounded-xl p-2 cursor-pointer flex items-center justify-center w-10 h-10"
                    >
                        <ChevronRightIcon className="w-4 h-4 text-gray-800"/>
                    </div>
                ) : (
                    <>
                        <div className="flex items-center">
                            <LogoIcon className="mr-2 w-4 h-4 text-gray-800"/>
                            <span className="text-xl font-bold text-gray-800 w-[150px]">
                    Reviews Farm
                </span>
                        </div>
                        <div
                            onClick={toggleCollapse}
                            className="bg-primary rounded-xl p-2 cursor-pointer flex items-center justify-center w-10 h-10 ml-auto"
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        >
                            <ChevronLeftIcon className="w-4 h-4 text-gray-800"/>
                        </div>
                    </>
                )}
            </div>

            {/* Menu */}
            {<Menu
                menuItemStyles={{
                    button: ({active}: { active: boolean }) => {
                        return {
                            backgroundColor: active ? '#e7edf3' : undefined,
                            '&:hover': {
                                backgroundColor: '#e7edf3',
                            },
                            margin: '5px 3px',
                            borderRadius: '15px'
                        };
                    },
                }}>
                <MenuItem
                    className="text-gray-800"
                    component={<Link to="/campaigns"/>}
                    icon={<MegaphoneIcon className="w-4 h-4 text-gray-800"/>}
                    active={location.pathname === '/campaigns'}
                >
                    Кампании
                </MenuItem>
                <MenuItem
                    className="text-gray-800"
                    component={<Link to="/reviews"/>}
                    icon={<MessageIcon className="w-4 h-4 text-gray-800"/>}
                    active={location.pathname === '/reviews'}
                >
                    Отзывы
                </MenuItem>
                <MenuItem
                    className="text-gray-800"
                    component={<Link to="/profiles"/>}
                    icon={<PersonIcon className="w-4 h-4 text-gray-800"/>}
                    active={location.pathname === '/profiles'}
                >
                    Профили
                </MenuItem>
                <MenuItem
                    className="text-gray-800"
                    component={<Link to="/audits"/>}
                    icon={<ChartIcon className="w-4 h-4 text-gray-800"/>}
                    active={location.pathname === '/audits'}
                >
                    Аудиты
                </MenuItem>
            </Menu>}
        </Sidebar>
    );
};

export default SideBar;
