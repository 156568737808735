import axiosInstance from '../utils/axiosConfig';
import {AuditGroupModel} from "../models/AuditGroupModel";
import {PaginationModel} from "../models/PaginationModel";
import {CreateAuditGroupModel} from "../models/CreateAuditGroupModel";
import {EditAuditGroupModel} from "../models/EditAuditGroupModel";

export async function fetchAuditGroups(page: number, limit: number, auditGroupName: string, isHidden: boolean): Promise<PaginationModel<AuditGroupModel>> {
    try {
        const params: Record<string, string | number | boolean> = {
            page,
            limit,
        };

        if (auditGroupName) {
            params["filter.name"] = `$ilike:${auditGroupName}`;
        }

        params["filter.isHidden"] = isHidden;

        const response = await axiosInstance.get('/audit-groups', {params});

        return response.data;
    } catch (error) {
        console.error('Failed to fetch audit groups', error);
        throw new Error('Failed to fetch audit groups');
    }
}

export async function createAuditGroup(auditData: CreateAuditGroupModel): Promise<void> {
    try {
        await axiosInstance.post("/audit-groups", auditData);
    } catch (error) {
        console.error('Failed to create audit group', error);
        throw new Error('Failed to create audit group');
    }
}

export async function downloadAuditGroupReport(auditGroupId: number): Promise<void> {
    try {
        const response = await axiosInstance.get(`/audit-groups/${auditGroupId}/report`, {
            responseType: 'blob',
        });

        const link = document.createElement('a');
        const url = window.URL.createObjectURL(response.data);
        link.href = url;
        link.download = `report-${auditGroupId}`;
        link.click();

        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Failed to download audit group report', error);
        throw new Error('Failed to download audit group report');
    }
}

export async function getAuditGroupById(id: number): Promise<AuditGroupModel> {
    try {
        return (await axiosInstance.get(`/audit-groups/${id}`)).data;
    } catch (error) {
        console.error('Failed to get audit group', error);
        throw new Error('Failed to get audit group');
    }
}

export async function editAuditGroupById(id: number, data: EditAuditGroupModel): Promise<void> {
    try {
        await axiosInstance.patch(`/audit-groups/${id}`, data);
    } catch (error) {
        console.error('Failed to edit audit group', error);
        throw new Error('Failed to edit audit group');
    }
}
