import React from 'react';

interface IconProps {
    className?: string;
}

const PauseIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 20.2832 19.9316">
            <g>
                <rect height="19.9316" opacity="0" width="20.2832" x="0" y="0"/>
                <path
                    d="M9.96094 19.9219C15.459 19.9219 19.9219 15.459 19.9219 9.96094C19.9219 4.46289 15.459 0 9.96094 0C4.46289 0 0 4.46289 0 9.96094C0 15.459 4.46289 19.9219 9.96094 19.9219ZM9.96094 18.2617C5.37109 18.2617 1.66016 14.5508 1.66016 9.96094C1.66016 5.37109 5.37109 1.66016 9.96094 1.66016C14.5508 1.66016 18.2617 5.37109 18.2617 9.96094C18.2617 14.5508 14.5508 18.2617 9.96094 18.2617Z"
                    fillOpacity="0.85"/>
                <path
                    d="M7.19727 13.7695L8.1543 13.7695C8.64258 13.7695 8.86719 13.5059 8.86719 13.125L8.86719 6.77734C8.86719 6.40625 8.64258 6.14258 8.1543 6.14258L7.19727 6.14258C6.71875 6.14258 6.49414 6.40625 6.49414 6.77734L6.49414 13.125C6.49414 13.5059 6.71875 13.7695 7.19727 13.7695ZM11.7676 13.7695L12.7246 13.7695C13.1934 13.7695 13.418 13.5059 13.418 13.125L13.418 6.77734C13.418 6.40625 13.1934 6.14258 12.7246 6.14258L11.7676 6.14258C11.2793 6.14258 11.0547 6.40625 11.0547 6.77734L11.0547 13.125C11.0547 13.5059 11.2793 13.7695 11.7676 13.7695Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default PauseIcon;
