import React, {useEffect, useRef, useState} from 'react';
import {AuditGroupModel} from '../models/AuditGroupModel';
import Button from "../components/Button";
import {useNavigate, useSearchParams} from 'react-router-dom';
import Loader from "../components/Loader";
import KebabIcon from "../icons/Kebab";
import ReportIcon from "../icons/Report";
import BankIcon from "../icons/Bank";
import {downloadAuditGroupReport, editAuditGroupById, fetchAuditGroups} from "../services/auditGroupService";
import {useMediaQuery} from "react-responsive";
import EditIcon from "../icons/Edit";
import HideIcon from "../icons/Hide";
import ShowIcon from "../icons/Show";

const AuditGroupsPage = () => {
    const [auditGroups, setAuditGroups] = useState<AuditGroupModel[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const pageSize = 30;
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const [selectedAudit, setSelectedAudit] = useState<AuditGroupModel | null>(null);
    const dropDown = useRef<HTMLDivElement | null>(null)
    const page = parseInt(searchParams.get('page') || '1', 10);
    const auditGroupName = searchParams.get('audit') || '';
    const isMobile = useMediaQuery({query: "(max-width: 768px)"});

    const handleToggleHide = () => {
        setIsHidden(!isHidden)
    }

    const handleAuditGroupReportDownload = async (auditGroupId: number) => {
        await downloadAuditGroupReport(auditGroupId)
        setDropdownOpen(false)
    }

    const handleAuditBranches = async (auditId: number) => {
        navigate(`/audit/${auditId}/branches`)
        setDropdownOpen(false)
    }

    const handleAuditEdit = async (auditId: number) => {
        navigate(`/audit/${auditId}/edit`)
        setDropdownOpen(false)
    }

    const handleAuditHideToggle = async (auditGroup: AuditGroupModel) => {
        await editAuditGroupById(auditGroup.id, {isHidden: !auditGroup.isHidden})
        setAuditGroups(auditGroups.filter(group => group.id !== auditGroup.id))
        setDropdownOpen(false)
    }

    const handleAuditGroupNameChange = (auditName: string) => {
        const params: Record<string, string> = {
            page: "1",
        };

        if (auditName) {
            params.audit = auditName;
        }

        setSearchParams(params);
    };

    const toggleDropdown = (audit: AuditGroupModel) => {
        setSelectedAudit(audit);
        setDropdownOpen(!dropdownOpen);
    };

    const closeDropDowns = (e: MouseEvent) => {
        const target = e.target as HTMLElement;

        if (dropdownOpen && !dropDown.current?.contains(target)) {
            setDropdownOpen(false)
        }
    }

    document.addEventListener('mousedown', closeDropDowns)

    useEffect(() => {
        const loadAudits = async () => {
            try {
                setIsLoading(true)
                const pagination = await fetchAuditGroups(page, pageSize, auditGroupName, isHidden);
                setAuditGroups(pagination.data);
                setTotalPages(pagination.meta.totalPages);
                setTotalItems(pagination.meta.totalItems)
                setIsLoading(false)
            } catch (error) {
                console.error('Failed to load auditGroups', error);
            }
        };

        void loadAudits();
    }, [page, auditGroupName, isHidden]);

    const handleNextPage = () => {
        if (page < totalPages) setSearchParams({page: (page + 1).toString()});
    };

    const handlePrevPage = () => {
        if (page > 1) setSearchParams({page: (page - 1).toString()});
    };

    return (
        <div className="container mx-auto">
            <div className="flex flex-row items-center align-middle justify-between mb-4 sm:mb-6">
                <div className="flex flex-row gap-2 sm:gap-4 items-center">
                    <h1 className="text-2xl sm:text-4xl font-bold mb-0">Аудиты</h1>
                    <span
                        className={`text-sm sm:text-base block sm:mt-0 px-3 py-1 sm:py-2 rounded-xl font-medium bg-primary`}>{totalItems}</span>
                </div>
                <Button
                    label={isMobile ? '+' : 'Добавить'}
                    onClick={() => navigate('/create-audit')}
                    className="px-6 py-2 sm:px-10"
                />
            </div>

            <div className="flex flex-row gap-2 mb-4">
                <div className="relative sm:w-fit">
                    <input
                        type="text"
                        className="bg-primary rounded-lg py-2 pl-4 text-gray-700 shadow-sm focus:outline-none"
                        placeholder="Наименование"
                        value={auditGroupName || ""}
                        onChange={(e) => handleAuditGroupNameChange(e.target.value)}
                    />
                </div>
                <div className="bg-primary rounded-lg p-2 flex cursor-pointer" onClick={handleToggleHide}>
                    {isHidden ? <ShowIcon className="w-5 h-5"/> : <HideIcon className="w-5 h-5"/>}
                </div>
            </div>

            <div className="border border-gray-200 rounded-lg overflow-x-scroll">
                <table className="table-auto w-full text-center whitespace-no-wrap">
                    <thead>
                    {!isLoading && <tr className="border-b border-gray-200">
                        <th className="px-2 sm:px-4 py-5 font-medium text-gray-500">#</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Наименование</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Описание</th>
                        <th className="px-2 sm:px-4 py-5 font-medium hidden sm:table-cell">Дата создания</th>
                        <th className="px-2 sm:px-4 py-5 font-medium">Действия</th>
                    </tr>}
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan={5} className="px-4 py-4 text-center">
                                <Loader/>
                            </td>
                        </tr>
                    ) : auditGroups.length === 0 ? (
                        <tr>
                            <td colSpan={5} className="px-4 py-4 text-center text-gray-500">
                                Аудиты не найдены
                            </td>
                        </tr>
                    ) : (
                        auditGroups.map((auditGroup, index) => (
                            <tr key={index} className="border-t border-gray-200 hover:bg-gray-100">
                                <td className="px-2 sm:px-4 py-2 text-gray-500">{auditGroup.id}</td>
                                <td className="px-2 sm:px-4 py-2 max-w-48">{auditGroup.name}</td>
                                <td className="px-2 sm:px-4 py-2 max-w-48 text-xs"><span
                                    className=" line-clamp-2">{auditGroup.description}
                                </span>
                                </td>
                                <td className="px-2 sm:px-4 py-2 hidden sm:table-cell">{new Date(auditGroup.createdAt).toLocaleString("ru-RU", {timeZone: 'Asia/Almaty'})}</td>
                                <td className="px-2 sm:px-4 py-2 relative">
                                    <button
                                        onClick={() => toggleDropdown(auditGroup)}
                                        className="inline-flex items-center p-2 text-sm font-medium text-gray-900 rounded-lg hover:bg-gray-100 focus:outline-none"
                                    >
                                        <KebabIcon className="w-5 h-5"/>
                                    </button>

                                    {/* Dropdown menu */}
                                    {(dropdownOpen && selectedAudit?.id === auditGroup.id) && (
                                        <div ref={dropDown}
                                             className="absolute right-0 top-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-32">
                                            <ul className="py-2 text-sm text-gray-700"
                                                aria-labelledby="dropdownMenuIconButton">
                                                <li className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleAuditBranches(auditGroup.id)}>
                                                    <BankIcon className="mr-2 w-5 h-5"/>
                                                    <p className="text-gray-700 hover:text-gray-900">Филиалы</p>
                                                </li>
                                                <li className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleAuditGroupReportDownload(auditGroup.id)}>
                                                    <ReportIcon className="mr-2 w-5 h-5"/>
                                                    <p className="text-gray-700 hover:text-gray-900">КП</p>
                                                </li>
                                                <li className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleAuditEdit(auditGroup.id)}>
                                                    <EditIcon className="mr-2 w-5 h-5"/>
                                                    <p className="text-gray-700 hover:text-gray-900">Изменить</p>
                                                </li>
                                                <li className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleAuditHideToggle(auditGroup)}>
                                                    {auditGroup.isHidden ? <ShowIcon className="mr-2 w-5 h-5"/> :
                                                        <HideIcon className="mr-2 w-5 h-5"/>}
                                                    <p className="text-gray-700 hover:text-gray-900">{auditGroup.isHidden ? 'Показать' : 'Скрыть'}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>

            {!isLoading && <div className="mt-4 flex flex-row justify-between items-center">
                <Button
                    label="Назад"
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
                <span
                    className="inline-flex items-center justify-center h-10 leading-none text-center">{page}/{totalPages}</span>
                <Button
                    label="Вперед"
                    onClick={handleNextPage}
                    disabled={page >= totalPages}
                    className="px-6 py-2 sm:px-10 mb-0 h-10 flex items-center"
                />
            </div>}
        </div>
    );

};

export default AuditGroupsPage;
