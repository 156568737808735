// services/gptService.ts
import axiosInstance from '../utils/axiosConfig';

export async function fetchReviewFromGpt(prompt: string): Promise<string> {
    try {
        const response = await axiosInstance.post('/openai/test-reviews', {
            prompt
        });

        const data = response.data;

        return data.review;
    } catch (error) {
        console.error('Error fetching review from GPT:', error);
        throw new Error('Failed to fetch review');
    }
}

export async function fetchProfileNames(count: number): Promise<string[]> {
    try {
        const response = await axiosInstance.get('/openai/profile-names', {
            params: {count}
        });

        const data = response.data;

        return data.names;
    } catch (error) {
        console.error('Error fetching profile names:', error);
        throw new Error('Failed to fetch profile names');
    }
}
