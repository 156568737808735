import React, {useEffect, useState} from 'react';
import TextInput from "../components/TextInput";
import TimeInput from "../components/TimeInput";
import TextArea from "../components/TextArea";
import RatingDistribution, {Percentages} from "../components/RatingDistribution";
import Calendar from "../components/Calendar";
import Button from "../components/Button";
import {getCampaignById, updateCampaign} from '../services/campaignService';
import {useNavigate, useParams} from 'react-router-dom';
import {CampaignReviewRatingModel} from "../models/CampaignReviewRatingModel";
import Modal from "../components/Modal";
import {UpdateCampaignModel} from "../models/UpdateCampaignModel";
import {CampaignModel} from "../models/CampaignModel";

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const EditCampaignPage = () => {
    const {id} = useParams<{ id: string }>();
    const [reviewsPerDay, setReviewsPerDay] = useState('');
    const [ratings, setRatings] = useState<CampaignReviewRatingModel[]>([{rating: 5, percent: 1.0}]);
    const [gptPrompt, setGptPrompt] = useState('');
    const [campaign, setCampaign] = useState<CampaignModel | null>(null);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const fetchedCampaign = await getCampaignById(Number(id));
                setCampaign(fetchedCampaign);
                setReviewsPerDay(fetchedCampaign.maxReviewsPerDay.toString());
                setRatings(fetchedCampaign.ratings);
                setGptPrompt(fetchedCampaign.gptPrompt);
            } catch (error) {
                console.error("Failed to fetch campaign:", error);
            }
        };

        void fetchCampaign();
    }, [id]);

    const handleEditCampaign = () => {
        setIsModalOpen(true);
    };

    const handleConfirmEditing = async () => {
        const campaignData: UpdateCampaignModel = {
            maxReviewsPerDay: Number(reviewsPerDay),
            ratings: ratings.map((rating) => ({percent: Number(rating.percent), rating: rating.rating})),
            gptPrompt
        };

        try {
            await updateCampaign(Number(id), campaignData);
            navigate("/campaigns")
        } catch (error) {
            console.error("Failed to update campaign:", error);
        }
    };

    const setPercentages = (percentages: Percentages) => {
        const ratings = Object.entries(percentages).map(([key, percentage]) => ({
            rating: Number(key),
            percent: percentage / 100.0
        })) as CampaignReviewRatingModel[];

        setRatings(ratings);
    };

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl font-bold mb-6">Редактирование</h1>

            {campaign && (<div className="space-y-6">
                {/* Campaign Name Input */}
                <div>
                    <label className="block text-sm font-bold mb-2">Название</label>
                    <TextInput
                        label="Название"
                        value={campaign.name}
                        setValue={() => {
                        }}
                        disabled={true}
                    />
                </div>


                {/* 2GIS Map URL Input */}
                <div>
                    <label className="block text-sm font-bold mb-2">2GIS ссылка</label>
                    <TextInput
                        label="2Gis ссылка"
                        value={campaign.twoGisUrl}
                        setValue={() => {
                        }}
                        disabled={true}
                    />
                </div>

                {/* Reviews per day Input */}
                <div>
                    <label className="block text-sm font-bold mb-2">Количество отзывов в день</label>
                    <TextInput
                        label="Количество отзывов в день"
                        value={reviewsPerDay.toString()}
                        setValue={(value) => {
                            setReviewsPerDay(value)
                        }}
                    />
                </div>

                {/* Calendar Component for review period */}
                <div>
                    <h3 className="text-lg font-semibold">Период</h3>
                    <Calendar
                        selectedDate={[new Date(campaign.periodStart) as ValuePiece, new Date(campaign.periodEnd) as ValuePiece] as Value}
                        setSelectedDate={() => {
                        }}
                        label="Период"
                    />
                </div>

                {/* Time Inputs */}
                <div className="flex space-x-4">
                    <div className="flex flex-col">
                        <label className="block text-sm font-bold mb-2">Время начала</label>
                        <TimeInput
                            value={campaign.dailyStartTime}
                            setValue={() => {
                            }}
                            disabled={true}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-bold mb-2">Время окончания</label>
                        <TimeInput
                            value={campaign.dailyEndTime}
                            setValue={() => {
                            }}
                            disabled={true}
                        />
                    </div>
                </div>

                <section>
                    <div className="flex flex-col w-full">
                        <label className="block text-sm font-bold mb-2">GPT промпт</label>
                        <TextArea
                            placeholder="Введите свой GPT промпт..."
                            value={gptPrompt}
                            setValue={(value) => {
                                setGptPrompt(value)
                            }}
                        />
                    </div>
                </section>

                {/* Rating Distribution */}
                <RatingDistribution
                    initialPercentages={{
                        5: (ratings.find(r => r.rating === 5)?.percent ?? 0) * 100,
                        4: (ratings.find(r => r.rating === 4)?.percent ?? 0) * 100,
                        3: (ratings.find(r => r.rating === 3)?.percent ?? 0) * 100,
                        2: (ratings.find(r => r.rating === 2)?.percent ?? 0) * 100,
                        1: (ratings.find(r => r.rating === 1)?.percent ?? 0) * 100,
                    }}
                    setPercentages={setPercentages}
                />


                {/* Create Campaign Button */}
                <Button
                    label="Сохранить"
                    onClick={handleEditCampaign}
                />
            </div>)}

            {/* Confirmation Modal */}
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAction={handleConfirmEditing}
                title="Подтверждение редактирования кампании"
                actionLabel="Подтвердить"
                description="Вы уверены, что хотите отредактировать данную кампанию?"
            />
        </div>
    );
};

export default EditCampaignPage;
