import React from 'react';

interface IconProps {
    className?: string;
}

const Warning: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 20.2832 19.9316">
            <g>
                <rect height="19.9316" opacity="0" width="20.2832" x="0" y="0"/>
                <path
                    d="M9.96094 19.9219C15.459 19.9219 19.9219 15.459 19.9219 9.96094C19.9219 4.46289 15.459 0 9.96094 0C4.46289 0 0 4.46289 0 9.96094C0 15.459 4.46289 19.9219 9.96094 19.9219ZM9.96094 18.2617C5.37109 18.2617 1.66016 14.5508 1.66016 9.96094C1.66016 5.37109 5.37109 1.66016 9.96094 1.66016C14.5508 1.66016 18.2617 5.37109 18.2617 9.96094C18.2617 14.5508 14.5508 18.2617 9.96094 18.2617Z"
                    fillOpacity="0.85"/>
                <path
                    d="M9.95117 11.7188C10.4102 11.7188 10.6836 11.4551 10.6934 10.9473L10.8398 5.79102C10.8496 5.29297 10.459 4.92188 9.94141 4.92188C9.41406 4.92188 9.04297 5.2832 9.05273 5.78125L9.17969 10.9473C9.18945 11.4453 9.46289 11.7188 9.95117 11.7188ZM9.95117 14.8926C10.5078 14.8926 10.9961 14.4434 10.9961 13.877C10.9961 13.3105 10.5176 12.8613 9.95117 12.8613C9.375 12.8613 8.89648 13.3203 8.89648 13.877C8.89648 14.4336 9.38477 14.8926 9.95117 14.8926Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>

    );
};

export default Warning;
