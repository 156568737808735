import React, {useState} from 'react';
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import {useNavigate} from 'react-router-dom';
import Modal from "../components/Modal";
import {CreateAuditGroupModel} from "../models/CreateAuditGroupModel";
import {createAuditGroup} from "../services/auditGroupService";

const CreateAuditGroupPage = () => {
    const [auditGroupName, setAuditGroupName] = useState('');
    const [auditGroupDescription, setAuditGroupDescription] = useState('');
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCreateAudit = () => {
        if (validateForm()) {
            setIsModalOpen(true);
        }
    };

    const [errors, setErrors] = useState({
        auditGroupName: '',
        auditGroupDescription: '',
    });

    const validateForm = () => {
        const newErrors = {
            auditGroupName: '',
            auditGroupDescription: '',
        };

        if (!auditGroupName.trim()) {
            newErrors.auditGroupName = 'Название аудита обязательно';
        }

        setErrors(newErrors);

        return Object.values(newErrors).every(error => error === '');
    };

    const clearError = (field: keyof typeof errors) => {
        setErrors(prevErrors => ({...prevErrors, [field]: ''}));
    };

    const handleConfirmCreation = async () => {
        if (validateForm()) {
            const auditData: CreateAuditGroupModel = {
                name: auditGroupName,
                description: auditGroupDescription || null,
            };

            try {
                await createAuditGroup(auditData);

                setAuditGroupName('');
                setAuditGroupDescription('');
                setErrors({
                    auditGroupName: '',
                    auditGroupDescription: '',
                });

                navigate("/audits")
            } catch (error) {
                console.error("Failed to create audit:", error);
            }
        }
    };

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl font-bold mb-6">Создание аудита</h1>

            <div className="space-y-6">
                <TextInput
                    label="Название"
                    value={auditGroupName}
                    setValue={(value) => {
                        setAuditGroupName(value);
                        clearError('auditGroupName');
                    }}
                    error={errors.auditGroupName}
                />

                <TextInput
                    label="Описание"
                    value={auditGroupDescription}
                    setValue={(value) => {
                        setAuditGroupDescription(value);
                        clearError('auditGroupDescription');
                    }}
                    error={errors.auditGroupDescription}
                />

                <Button
                    label="Создать"
                    onClick={handleCreateAudit}
                    disabled={Object.values(errors).some(error => error)}
                />
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAction={handleConfirmCreation}
                title="Подтверждение создания аудита"
                actionLabel="Подтвердить"
                description="Вы уверены, что хотите создать этот аудит?"
            />
        </div>
    );
};

export default CreateAuditGroupPage;
