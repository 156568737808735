import React, {useEffect, useState} from 'react';
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import {useNavigate, useParams} from 'react-router-dom';
import Modal from "../components/Modal";
import {EditAuditGroupModel} from "../models/EditAuditGroupModel";
import {editAuditGroupById, getAuditGroupById} from "../services/auditGroupService";

const EditAuditGroupPage = () => {
    const {id} = useParams<{ id: string }>();
    const [auditGroupName, setAuditGroupName] = useState('');
    const [auditGroupDescription, setAuditGroupDescription] = useState('');
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchCampaign = async () => {
            try {
                const fetchedAuditGroup = await getAuditGroupById(Number(id));
                setAuditGroupName(fetchedAuditGroup.name);
                setAuditGroupDescription(fetchedAuditGroup.description);
            } catch (error) {
                console.error("Failed to fetch campaign:", error);
            }
        };

        void fetchCampaign();
    }, [id]);

    const handleEditAudit = () => {
        if (validateForm()) {
            setIsModalOpen(true);
        }
    };

    const [errors, setErrors] = useState({
        auditGroupName: '',
        auditGroupDescription: '',
    });

    const validateForm = () => {
        const newErrors = {
            auditGroupName: '',
            auditGroupDescription: '',
        };

        if (!auditGroupName.trim()) {
            newErrors.auditGroupName = 'Название аудита обязательно';
        }

        setErrors(newErrors);

        return Object.values(newErrors).every(error => error === '');
    };

    const clearError = (field: keyof typeof errors) => {
        setErrors(prevErrors => ({...prevErrors, [field]: ''}));
    };

    const handleConfirmEdition = async () => {
        if (validateForm()) {
            const auditData: EditAuditGroupModel = {
                name: auditGroupName,
                description: auditGroupDescription || null,
            };

            try {
                if (!id) return;

                await editAuditGroupById(parseInt(id), auditData);

                setAuditGroupName('');
                setAuditGroupDescription('');
                setErrors({
                    auditGroupName: '',
                    auditGroupDescription: '',
                });

                navigate("/audits")
            } catch (error) {
                console.error("Failed to edit audit:", error);
            }
        }
    };

    return (
        <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl font-bold mb-6">Редактирование аудита</h1>

            <div className="space-y-6">
                <TextInput
                    label="Название"
                    value={auditGroupName}
                    setValue={(value) => {
                        setAuditGroupName(value);
                        clearError('auditGroupName');
                    }}
                    error={errors.auditGroupName}
                />

                <TextInput
                    label="Описание"
                    value={auditGroupDescription}
                    setValue={(value) => {
                        setAuditGroupDescription(value);
                        clearError('auditGroupDescription');
                    }}
                    error={errors.auditGroupDescription}
                />

                <Button
                    label="Изменить"
                    onClick={handleEditAudit}
                    disabled={Object.values(errors).some(error => error)}
                />
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAction={handleConfirmEdition}
                title="Подтверждение создания аудита"
                actionLabel="Подтвердить"
                description="Вы уверены, что хотите изменить этот аудит?"
            />
        </div>
    );
};

export default EditAuditGroupPage;
