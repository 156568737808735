import React from 'react';

interface IconProps {
    className?: string;
}

const ResumeIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 20.2832 19.9316">
            <g>
                <rect height="19.9316" opacity="0" width="20.2832" x="0" y="0"/>
                <path
                    d="M9.96094 19.9219C15.459 19.9219 19.9219 15.459 19.9219 9.96094C19.9219 4.46289 15.459 0 9.96094 0C4.46289 0 0 4.46289 0 9.96094C0 15.459 4.46289 19.9219 9.96094 19.9219ZM9.96094 18.2617C5.37109 18.2617 1.66016 14.5508 1.66016 9.96094C1.66016 5.37109 5.37109 1.66016 9.96094 1.66016C14.5508 1.66016 18.2617 5.37109 18.2617 9.96094C18.2617 14.5508 14.5508 18.2617 9.96094 18.2617Z"
                    fillOpacity="0.85"/>
                <path
                    d="M8.13477 13.7793L13.6914 10.4883C14.1016 10.2539 14.0918 9.6875 13.6914 9.44336L8.13477 6.15234C7.71484 5.9082 7.1582 6.09375 7.1582 6.57227L7.1582 13.3594C7.1582 13.8281 7.67578 14.0527 8.13477 13.7793Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default ResumeIcon;
