import React from 'react';

interface IconProps {
    className?: string;
}

const BankIcon: React.FC<IconProps> = ({className}) => {
    return (
        <svg fill="currentColor" className={className} version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.168 20.8496">
            <g>
                <rect height="20.8496" opacity="0" width="22.168" x="0" y="0"/>
                <path
                    d="M1.29883 18.5156L20.5078 18.5156L20.5078 17.8613C20.5078 17.4707 20.2832 17.2363 19.8828 17.2363L19.2578 17.2363L19.2578 6.69922L20.1172 6.69922C20.6543 6.69922 20.9668 6.30859 20.9668 5.9082C20.9668 5.61523 20.8008 5.36133 20.4395 5.15625L12.0996 0.341797C11.709 0.117188 11.2988 0 10.8984 0C10.498 0 10.0781 0.117188 9.70703 0.341797L1.36719 5.15625C0.996094 5.36133 0.839844 5.61523 0.839844 5.9082C0.839844 6.30859 1.15234 6.69922 1.68945 6.69922L2.54883 6.69922L2.54883 17.2363L1.93359 17.2363C1.5332 17.2363 1.29883 17.4707 1.29883 17.8613ZM4.02344 17.2363L4.02344 5.44922L10.5176 1.80664C10.6348 1.73828 10.7715 1.69922 10.8984 1.69922C11.0254 1.69922 11.1621 1.73828 11.2891 1.80664L17.7832 5.44922L17.7832 17.2363ZM0.78125 20.8398L21.0156 20.8398C21.4453 20.8398 21.8066 20.4785 21.8066 20.0488C21.8066 19.6191 21.4453 19.2676 21.0156 19.2676L0.78125 19.2676C0.351562 19.2676 0 19.6191 0 20.0488C0 20.4785 0.351562 20.8398 0.78125 20.8398Z"
                    fillOpacity="0.85"/>
                <path
                    d="M10.918 14.541C12.5781 14.541 14.043 13.7793 14.043 12.0996C14.043 10.625 12.8613 10.0586 11.5234 9.74609L10.5957 9.53125C9.74609 9.33594 9.02344 8.97461 9.02344 8.17383C9.02344 7.24609 10.0195 6.83594 10.918 6.83594C11.8945 6.83594 12.5586 7.25586 12.8223 8.06641C12.9199 8.36914 13.1055 8.51562 13.3691 8.51562C13.6133 8.51562 13.8965 8.34961 13.8965 8.00781C13.8965 6.75781 12.2949 5.86914 10.918 5.86914C9.3457 5.86914 7.92969 6.74805 7.92969 8.26172C7.92969 9.7168 9.15039 10.2637 10.3516 10.5566L11.2891 10.7715C12.1582 10.9766 12.959 11.2891 12.959 12.1875C12.959 13.252 11.9336 13.584 10.9473 13.584C9.90234 13.584 9.18945 13.252 8.83789 12.3145C8.7207 12.0117 8.55469 11.8945 8.29102 11.8945C8.00781 11.8945 7.77344 12.0801 7.77344 12.4023C7.77344 12.4902 7.79297 12.5977 7.82227 12.7051C8.20312 13.9941 9.63867 14.541 10.918 14.541ZM10.9082 15.7031C11.084 15.7031 11.2305 15.5566 11.2305 15.3809L11.2305 5.05859C11.2305 4.87305 11.084 4.73633 10.9082 4.73633C10.7324 4.73633 10.5859 4.87305 10.5859 5.05859L10.5859 15.3809C10.5859 15.5566 10.7324 15.7031 10.9082 15.7031Z"
                    fillOpacity="0.85"/>
            </g>
        </svg>
    );
};

export default BankIcon;
